<template>
  <section class="dtc-main-section mt-4" id="">
    <div
      class="dtc-print"
      v-for="(topItem, topIndex) in dtcGridSize"
      :key="topIndex"
      style="page-break-after:always;pointer-events:none;"
      :sty2le="topIndex > 1 ? 'page-break-after:always;' : ''"
    >
      <section class="dtc-print7-grid-header">
        <span
          class="dtc-title"
          v-if="topIndex == 0"
          style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
          >衛生福利部{{ bYear }}學年度第{{term}}學期「原住民族及離島地區醫事人員養成計畫」公費生 待遇補助經費概算審定表</span
        >
        <div class="dtc-fill-required">培育學校</div>
        <div class="dtc-fill-required2">人數</div>
        <section class="sec1">
          <header>註冊費</header>
          <div style="text-align: center; border-bottom: 0;">學雜費</div>
          <div style="border-bottom:0;">學生平安保險費</div>
          <div style="border-bottom:0;">其他相關費用</div>
        </section>
        <section class="sec2">
          <header>生活費</header>
          <div style="border-bottom:0;">膳食費</div>
          <div style="border-bottom:0;">零用津貼</div>
          <div style="border-bottom:0;">課業費</div>
          <div style="border-bottom:0;">書籍費</div>
          <div style="border-bottom:0;">制服費</div>
          <div style="border-bottom:0;">應屆畢業生旅行參訪費</div>
        </section>
        <section class="sec3">
          <header>旅宿費</header>
          <div style="text-align: center; border-bottom:0;">返鄉旅費</div>
          <div style="text-align: center; border-bottom:0;">住宿費</div>
        </section>
        <div >設備費</div>
        <div style="border-right:0">小計</div>
      </section>
      <main v-for="item,key in pageRows"
        :key="item.myKey"
        class="dtc-table" v-if="key==pageRows.length-1">
        <div>{{ item.schoolName }}</div>
        <div><div class="pt-1" style=" border-right:none;">{{ item.personCount }}</div></div>
        <section class="dtc-s1" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(item.learnSpend$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.insure$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.others$) }}</div>
        </section>
        <section class="dtc-s2" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(item.eat$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.allow$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.class$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.book$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.cloth$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.travel$) }}</div>
        </section>
        <section class="dtc-s3" style="border:none;">
          <div class="text-right-mode">{{ $formatPrice(item.backHome$) }}</div>
          <div class="text-right-mode">{{ $formatPrice(item.live$) }}</div>
        </section>
        <div><div style="border:none;">{{$formatPrice(item.device$)}}</div></div>
        <div style="border-right:none;"><div style="border:none;" class="text-right-mode">{{ $formatPrice(item.rowTotal) }}</div></div>
      </main>
      <footer class="dtc-footer-total">
        <div class="dtx-count">小計</div>
        <section class="dtc-s1" style="border:none;">
          <div style="border:none"></div>
          <div style="border:none" class="text-right-mode">{{ $formatPrice(learnFooter) }}</div>
          <div style=""></div>
        </section>
        <section class="dtc-s2" style="border:none;">
          <div style="border:none"></div>
          <div style="border:none"></div>
          <div style="border:none" class="text-right-mode">{{ $formatPrice(lifeFooter) }}</div>
          <div style="border:none"></div>
          <div style=""></div>
        </section>
        <section class="dtc-s3" style="border:none;">
          <div style="border:none"></div>
          <div class="text-right-mode">{{ $formatPrice(travelFooter) }}</div>
        </section>
        <div style="" class="" > {{ $formatPrice(deviceFooter) }}</div>
        <div style="border:none" class="text-right-mode" >{{ $formatPrice(sumAllFooter) }}</div>

        <div class="dtx-total" style="border:none;border-top:1px solid var(--dtc-border-color);"></div>
        <div class="dtx-total" style="border:none;border-top:1px solid var(--dtc-border-color);"></div>
        <div class="dtx-total" style="border-right:0;border-top:1px solid var(--dtc-border-color);">經常門總計(元)</div>
        <div class="dtx-total" style="border:none;border-top:1px solid var(--dtc-border-color);"></div>
        <div class="dtx-total" style="border:none;border-top:1px solid var(--dtc-border-color);"></div>
        <div
          class="dtx-total text-right-mode"
          style="border:none;padding-top:5px;text-align:right;padding-right:5px;border-top:1px solid var(--dtc-border-color);"
        >
          {{ $formatPrice(learnFooter + lifeFooter + travelFooter) }}
        </div>

        <div class="dtx-total" style="border:none"></div>
        <div class="dtx-total" style="border:none"></div>
        <div class="dtx-total" style="border-right:0">資本門總計(元)</div>
        <div class="dtx-total" style="border:none"></div>
        <div class="dtx-total" style="border:none"></div>
        <div
          class="dtx-total text-right-mode"
          style="border:none;padding-top:5px;text-align:right;padding-right:5px;"
        >
          {{ $formatPrice(deviceFooter) }}
        </div>

        <div class="dtx-total" style="border:none"></div>
        <div class="dtx-total" style="border:none"></div>
        <div class="dtx-total" style="border-right:0">經費合計(元)</div>
        <div class="dtx-total" style="border:none"></div>
        <div class="dtx-total" style="border:none"></div>
        <div
          class="dtx-total text-right-mode"
          style="border:none;padding-top:5px;text-align:right;padding-right:5px;"
        >
          {{ $formatPrice(sumAllFooter) }}
        </div>

      </footer>
    </div>
  </section>
</template>

<script>
import html2canvas from "html2canvas";
import queryString from "query-string";
const rowObj = {};
let pageRows = new Array(200).fill().map((s, t) => {
  return {
    myKey: t,
    schoolName: "",
    personCount: 0,
    learnSpend$: "",
    insure$: "",
    others$: "",
    eat$: "",
    allow$: "",
    class$: "",
    book$: "",
    cloth$: "",
    travel$: "",
    backHome$: "",
    live$: "",
    device$: "",
    rowTotal: 0
  };
});

export default {
  name: "totalSpend",
  data() {
    return {
      term: 1,
      bYear: "108",
      dtcGridSize: 1,
      schoolName: "",
      pageRows,
      learnFooter: 0,
      lifeFooter: 0,
      travelFooter: 0,
      deviceFooter: 0,
      sumFooter: 0,
      sumAllFooter: 0,
      debug: window.dtcDebug,
      supplement: "",
    };
  },

  methods: {
    async getData() {
      //this.learnSpendFooter = this.insureFooter = this.othersFooter = 0;
      //this.travelFooter = this.clothFooter = this.bookFooter = this.classFooter = this.allowFooter = this.eatFooter = 0;
      //this.backHomeFooter = this.liveFooter = 0;
      //this.sumFooter = 0;
      //this.resetRows();
      const { schoolcode, byear, term, category } = queryString.parse(
        location.href.split("?")[1]
      );
      this.term = +term;
      const url = `Budgeting/Get?schoolcode=${schoolcode}&byear=${byear}&term=${term}&category=${category}`;

      let map = "";
      try {
        map = await window.axios.get(url);
        if (Array.isArray(map)) {
          map = map[0];
        }
        if (map) {
          //this.map = map;
          const schoolName = this.schoolName;
          this.supplement = map.Supplement;
          //this.normalizeData(this.map);
          let personCount = 0, fee1 = 0, fee2 = 0, fee3 = 0, fee4 = 0, fee5 = 0, fee6 = 0, 
              fee7 = 0, fee8 = 0, fee9 = 0, fee10 = 0, fee11 = 0, fee12 = 0, total = 0;
              
          $.each(map.budgetings, function(i, e){
            personCount += e.NumberOfPeople;
            fee1 += e.Fee_1;
            fee2 += e.Fee_2;
            fee3 += e.Fee_3;
            fee4 += e.Fee_4;
            fee5 += e.Fee_5;
            fee6 += e.Fee_6;
            fee7 += e.Fee_7;
            fee8 += e.Fee_8;
            fee9 += e.Fee_9;
            fee10 += e.Fee_10;
            fee11 += e.Fee_11;
            fee12 += e.Fee_12;
            total += e.Subtotal;

            e.myKey= i;
            e.schoolName= schoolName;
            e.personCount= e.NumberOfPeople;
            e.learnSpend$= e.Fee_1;
            e.insure$= e.Fee_2;
            e.others$= e.Fee_3;
            e.eat$= e.Fee_4;
            e.allow$= e.Fee_5;
            e.class$= e.Fee_6;
            e.book$= e.Fee_7;
            e.cloth$= e.Fee_8;
            e.travel$= e.Fee_9;
            e.backHome$= e.Fee_10;
            e.live$= e.Fee_11;
            e.device$= e.Fee_12;
            e.rowTotal= e.Fee_1+e.Fee_2+e.Fee_3+e.Fee_4+e.Fee_5+e.Fee_6+e.Fee_7+e.Fee_8+e.Fee_9+e.Fee_10+e.Fee_11+e.Fee_12;
          });
          //console.log('資料',map.budgetings);
          this.pageRows = map.budgetings;
          this.pageRows.push({
            myKey: this.pageRows.length+1 ,
            schoolName: schoolName+'小計',
            personCount: personCount,
            learnSpend$: fee1,
            insure$: fee2,
            others$: fee3,
            eat$: fee4,
            allow$: fee5,
            class$: fee6,
            book$: fee7,
            cloth$: fee8,
            travel$: fee9,
            backHome$: fee10,
            live$: fee11,
            device$: fee12,
            rowTotal: total
          });
          //console.log('pageRows',this.pageRows);
          this.learnFooter = fee1 + fee2 + fee3;
          this.lifeFooter = fee4 + fee5 + fee6 + fee7 + fee8 + fee9;
          this.travelFooter = fee10 + fee11;
          this.deviceFooter = fee12;
          this.sumFooter = total - fee12;
          this.sumAllFooter = total;
        } else {
          //alert(`error server api ${url} return 200 but the data is: ` + map);
          const str = `error server api ${url} return status 200 but the data is null`;
          this.$root.$emit("dtc-server-error", str);
          //this.pageRows = [];
          // user can enter data into sheet; not need to empty rows
        }
      } catch (e) {
        this.debug = true;
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  async mounted() {
    const { schoolcode, byear, term, category } = queryString.parse(
      location.href.split("?")[1]
    );
    this.term = +term;
    this.bYear = +byear;

    //for check is same school-prevent see other school
    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolcode) {
        return;
      }
    }
    //check Can see and then get data
    //if (term == 1) {
    //  this.$router.push("/doc4-1?" + location.href.split("?")[1]);
    //  return;
    //}

    this.getData();
  },
  async beforeMount() {
    const schools = JSON.parse(localStorage.dtcSchoolList);
    const {
      schoolcode: SchoolCode = "0003",
      byear: Byear = "109",
      term = 2,
      category: Category = "S",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = +term;
    const { SchoolName } = schools.find((s) => s.SchoolCode == SchoolCode);
    this.schoolName = SchoolName;
    /*let arr = [];
    let url = `Major/Get?schoolcode=${SchoolCode}`;
    try {
      arr = await window.axios.get(`Major/Get?schoolcode=${SchoolCode}`);
    } catch (e) {
      const str = e + " API: " + url;
      this.$root.$emit("dtc-server-error", str);
    }
    let majors = [];
    let arr2 = [];
    arr.forEach((s) => {
      majors.push(`${s.MajorName}--${s.MajorId}`);
      arr2.push({ value: s.MajorId, text: `${s.MajorName}` });
    });
    this.category = arr2;
    this.majors = majors;*/
  },
};
</script>
<style lang="scss">
main .custom-select {
  background: none;
}
</style>
<style lang="scss">
.dtc-main-section ::v-deep .dropdown-toggle {
  min-width: 57px !important;
  font-size: 14px;
  background: white;
  color: black;
  &::after {
    all: unset;
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-print {
  position: relative;
  --dtc-border-color: var(--dark);
  margin-top: 30px;
  margin: 30px auto 2px auto;
  width: 1272px;
  margin-bottom: 60px;
  .input1 {
    border: none;
    max-width: 49px;
    padding: 3px;
  }
  .input2 {
    border: none;

    // height: 30px;
    display: inline-block;
    padding: 3px;
  }
}
.dtc-footer-d1 {
  //沒用到
  display: grid;
  place-items: center;
  > div {
    display: grid;
    grid-template-columns: max-content 220px max-content 220px max-content 220px max-content;
    grid-gap: 12px;
  }
}

.dtc-title {
  position: absolute;

  left: 50%;
  display: inline-block;
  text-align: center;
  width: 1272px;
  transform: translate(-50%, -30px);
  font-size: 16px;
  font-weight: bold;
}

.dtc-print7-grid-header {
  position: relative;
  z-index: 13px;
  font-size: 12px !important;
  display: grid;
  padding: 0px;
  border: 1px solid var(--dtc-border-color);
  width: max-content;
  grid-template-columns: 200px 50px 250px 380px 250px 120px 120px;
  > div {
    height: 150px;
    border-right: 1px solid var(--dtc-border-color);
    line-height: 150px;
    text-align: center;
  }
  .sec1,
  .sec2,
  .sec3 {
    z-index: 2px;
    display: grid;
    height: 150px;
    grid-template-columns: repeat(3, 1fr);
    header {
      grid-column: 1 / -1;
      border-bottom: 1px solid var(--dtc-border-color);
      border-right: 1px solid var(--dtc-border-color);
      text-align: center;
      height: 30px;
    }
    > div {
      border-bottom: 1px solid var(--dtc-border-color);
      border-right: 1px solid var(--dtc-border-color);
      padding: 3px;
      height: 120px;
    }
  }
  .sec2 {
    grid-template-columns: repeat(6, 1fr);
    footer {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      border: none;
      > div {
        padding: 3px;
        border-right: 1px solid var(--dtc-border-color);
        //line-height: calc(220px - 170px);
      }
    }
  }

  .sec3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.dtc-table,
.dtc-footer-total {
  text-align: center !important;
  position: relative;
  border-left: 1px solid var(--dtc-border-color);
  border-bottom: 1px solid var(--dtc-border-color);
  border-right: 1px solid var(--dtc-border-color);
  font-size: 16px !important;
  display: grid;
  width: 1372px;
  //50px repeat(2, 60px) 50px 270px 360px 250px 80px;
  //grid-template-columns: 200px 50px repeat(5, max-content );
  grid-template-columns: 200px 50px 250px 380px 250px 120px 120px  ;
  > * {
    // min-height: 30px !important;
    // max-height: 30px !important;
    border-right: 1px solid var(--dtc-border-color);
    > * {
      border-right: 1px solid var(--dtc-border-color);
      // min-height: 30px !important;
      // max-height: 30px !important;
    }
  }
}

.dtc-footer-total {
  grid-template-columns: 250px 250px 456px 174px 120px 120px;
  .dtx-count {
    text-align: center;
    // line-height: 30px;
    padding-top: 0px;
  }
}

.dtc-s1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  > div {
    padding-top: 5px;
  }
}
.dtc-s2 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  > div {
    padding-top: 5px;
  }
}

.dtc-s3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  > div {
    padding-top: 5px;
  }
}

.text-right-mode {
  text-align: right;
  padding-right: 5px;
}
@media screen {
  .dtc-main-section {
    padding-top: 12px;
  }
}

@media print {
  @page {
    size: A4 landscape;
  }
  .dtc-print {
    --dtc-border-color: var(--dark);
    max-width: 98vw;
  }
}
</style>